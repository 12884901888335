import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getConfig } from '@edx/frontend-platform';
import { FormattedMessage } from '@edx/frontend-platform/i18n';

/**
 * Course Card component that renders provided course data.
 */
const CourseCard = ({
  courseName,
  courseNumber,
  courseLink,
  courseImg,
  effort,
  courseShortDesc,
}) => (
  <li className="courses-listing-item is-courses-card">
    <article className="course" id="course.id" role="region" aria-label={courseName}>
      <Link to={courseLink} style={{ textDecoration: 'none' }}>
        <header className="course-image">
          <div className="cover-image">
            <img src={`${getConfig().LMS_BASE_URL}${courseImg}`} alt={`${courseName} ${courseNumber}`} />
            <div className="learn-more" aria-hidden="true">
              <FormattedMessage id="learnMore" defaultMessage="LEARN MORE" />
            </div>
          </div>
        </header>
        <div className="course-info">
          <div className="course-name">
            <h2 className="course-title">{courseName}</h2>
            {effort && (
              <div className="course-video-duration">
                <div>
                  <FormattedMessage id="effort" defaultMessage="Estimated Effort" />: {effort}
                </div>
              </div>
            )}
            {courseShortDesc && (
              <p className="course-short-desc">
                {courseShortDesc}
              </p>
            )}
          </div>
        </div>
      </Link>
    </article>
  </li>
);

CourseCard.propTypes = {
  courseName: PropTypes.string.isRequired,
  courseNumber: PropTypes.string.isRequired,
  courseLink: PropTypes.string.isRequired,
  courseImg: PropTypes.string.isRequired,
  effort: PropTypes.string,
  courseShortDesc: PropTypes.string,
};

CourseCard.defaultProps = {
  effort: null,
  courseShortDesc: null,
};

export default CourseCard;
