import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { useHistory } from 'react-router-dom';
import {
  getCourses, getCurricula, getProducts, getSeries,
} from '../api';

const CourseContext = createContext({});

const CourseContextProvider = injectIntl(({ children, intl }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [seriesData, setSeriesData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [curriculaData, setCurriculaData] = useState({});
  const [queryString, setQueryString] = useState('');
  const [selectedFacets, setSelectedFacets] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [infoMsg, setInfoMsg] = useState('');
  const [paginationMsg, setPaginationMsg] = useState('');
  const [facets, setFacets] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const coursePageSize = 16;

  const fetchCourses = (query, facetsData, pageIndex = 0) => getCourses(query, facetsData, pageIndex, coursePageSize)
    .then(({ results, aggs, total }) => {
      setLoading(true);
      setCurrentPage(pageIndex);
      setPageCount(Math.ceil(total / coursePageSize));
      setSearchResult(results);
      setFacets(aggs);
      let message;
      if (results.length > 0) {
        message = intl.formatMessage(
          { id: 'search.courseResults', defaultMessage: '{count} {count, plural, one {Course} other {Courses}}' },
          { count: total },
        );
      } else {
        message = intl.formatMessage(
          { id: 'search.noCourseResults', defaultMessage: 'We couldn\'t find any results for "{searchString}".' },
          { searchString: query },
        );
      }
      if (total > coursePageSize) {
        setPaginationMsg(intl.formatMessage({ id: 'search.pagination', defaultMessage: 'Please scroll down to see more' }));
      } else {
        setPaginationMsg('');
      }
      setInfoMsg(message);
    })
    .catch(() => {
      setSearchResult([]);
      setInfoMsg(intl.formatMessage({ id: 'error.search', defaultMessage: 'There was an error, try searching again.' }));
    });

  useEffect(() => {
    async function fetchData() {
      const queryParams = new URLSearchParams(window.location.search);
      const productParam = queryParams.get('product');
      let actualFacets = { ...selectedFacets };
      if (productParam) {
        actualFacets = { product: productParam };
        setSelectedFacets(actualFacets);
        queryParams.delete('product');
        history.replace({ search: queryParams.toString() });
      }
      await Promise.all([
        fetchCourses(queryString, actualFacets),
        getCurricula().then((curricula) => setCurriculaData(curricula)),
        getSeries().then((series) => setSeriesData(series)),
        getProducts().then((products) => setProductsData(products)),
      ]);
    }
    fetchData();
  }, []);

  const canLoadMore = () => currentPage < pageCount - 1;

  const handleLoadMore = (query, facetsData) => getCourses(query, facetsData, currentPage + 1, coursePageSize)
    .then(({ results }) => {
      setSearchResult((prevState) => [...prevState, ...results]);
      setCurrentPage((prevPage) => prevPage + 1);
    })
    .catch(() => {
      setSearchResult([]);
      setInfoMsg(intl.formatMessage({ id: 'error.search', defaultMessage: 'There was an error, try searching again.' }));
    });

  /**
  * Remove one facet from selected facets and search for courses.
  * @param {Event} e - button click event.
  * @param {string} key - facet name.
  */
  const handleClearFacet = (e, key) => {
    e.preventDefault();
    const facetsCopy = { ...selectedFacets };
    if (key in facetsCopy) {
      delete facetsCopy[key];
    }
    setSelectedFacets(facetsCopy);
    fetchCourses(queryString, facetsCopy);
  };

  /**
  * Remove all selected facets and search for courses.
  * @param {Event} e - button click event.
  */
  const handleClearAllFacets = (e) => {
    e.preventDefault();
    setSelectedFacets({});
    fetchCourses(queryString, {});
  };

  /**
  * Process facet select.
  * @param {Event} e - onChange event from select element.
  * @param {string} facet - Facet name.
  */
  const handleFacetChange = (e, facet) => {
    const selectedFacetsCopy = { ...selectedFacets };
    if (e.target.value) {
      selectedFacetsCopy[facet] = e.target.value;
    } else {
      delete selectedFacetsCopy[facet];
    }
    setSelectedFacets(selectedFacetsCopy);
    fetchCourses(queryString, selectedFacetsCopy);
  };

  const handleSearch = (value) => {
    setQueryString(value);
    fetchCourses(value, selectedFacets);
  };

  return (
    <CourseContext.Provider
      value={{
        loading,
        queryString,
        setQueryString,
        searchResult,
        seriesData,
        curriculaData,
        handleClearAllFacets,
        handleFacetChange,
        handleClearFacet,
        infoMsg,
        facets,
        selectedFacets,
        productsData,
        handleSearch,
        handleLoadMore,
        canLoadMore,
        paginationMsg,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
});

CourseContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  intl: intlShape.isRequired,
};

export { CourseContextProvider };
export default CourseContext;
