import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@edx/frontend-platform/i18n';

/**
 * Series Card component that renders provided program data.
 * Mimics the CourseCard component.
 */
const SeriesCard = ({series_id, title, image, link }) => (
  <li className="courses-listing-item">
    <article className="course" id={series_id} role="region" aria-label={title}>
      <a href={link}>
        <header className="course-image">
          <div className="cover-image">
            <img src={image} alt="series-img" />
            <div className="learn-more" aria-hidden="true">
              <FormattedMessage id="learnMore" defaultMessage="LEARN MORE" />
            </div>
          </div>
        </header>
        <div className="course-info" aria-hidden="true">
          <h2 className="course-name">
            <span className="course-title">{title}</span>
          </h2>
        </div>
      </a>
    </article>
  </li>
);

SeriesCard.defaultProps = {
  image: undefined,
};

SeriesCard.propTypes = {
  series_id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  link: PropTypes.string.isRequired,
};

export default SeriesCard;
