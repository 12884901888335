import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@edx/frontend-platform/i18n';
import SelectedFacetsList from './SelectedFacetsList';

/**
 * Filter Bar component that displays selected filters.
 */
const FilterBar = ({ selectedFacets, handleClearAllFacets, handleClearFacet }) => (
  <div id="filter-bar" className="filters hide-phone is-animated">
    <div className="filters-inner">
      <SelectedFacetsList selectedFacets={selectedFacets} handleClearFacet={handleClearFacet} />
      <button
        onClick={(e) => handleClearAllFacets(e)}
        id="clear-all-filters"
        className="clear-filters flt-right discovery-button"
        type="button"
      >
        <FormattedMessage id="clearFilters" defaultMessage="Clear Filters" />
      </button>
    </div>
  </div>
);

FilterBar.propTypes = {
  selectedFacets: PropTypes.objectOf(PropTypes.string).isRequired,
  handleClearFacet: PropTypes.func.isRequired,
  handleClearAllFacets: PropTypes.func.isRequired,
};

export default FilterBar;
