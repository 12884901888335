import frMessages from './messages/fr.json';
import jaMessages from './messages/ja_jp.json';
import esMessages from './messages/es_es.json';

const messages = {
  fr: frMessages,
  'es-es': esMessages,
  'ja-jp': jaMessages,
};

export default messages;
