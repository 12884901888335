import React , { useContext, useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { getConfig } from '@edx/frontend-platform';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';

import SearchContext from '../../context/SearchContext';
import { buildCourseList } from '../../utils';
import { LandingLine, VideoCard, SeriesCard } from '../../components';
import { getCurriculum } from '../../api';

/**
 * Curriculum component that renders body of curriculum about page.
 */
const CurriculumAbout = ({ intl }) => {
  const { curriculaData } = useContext(SearchContext);
  const { curriculumId } = useParams();
  const [curriculumData, setCurriculumData] = useState({});
  const [error, setError] = useState('');
  const [curriculumLoaded, setCurriculumLoaded] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => {
    getCurriculum(curriculumId).then(responseData => {
      setCurriculumData(responseData);
      setCurriculumLoaded(true);
    }).catch((err) => {
      if (err.customAttributes.httpErrorStatus === 404) {
        setIsNotFound(true);
      } else {
        setError(intl.formatMessage({
          id: 'error.default',
          defaultMessage: 'There was an error, try to reload the page.'
        }));
        setCurriculumLoaded(true);
      }
    });
  }, []);

  const buildSeriesList = (seriesData) => seriesData.map((series) => (
    <SeriesCard
      key={series.series_id}
      series_id={series.series_id}
      title={series.title}
      image={series.image}
      link={`/series/${series.series_id}`}
    />
  ));

  const buildVideosList = (videos) => videos.map((video, i) => (
    <VideoCard
      key={i.toString()}
      videoUrl={video.url}
      videoTitle={video.name}
      imgUrl={curriculumData.standalone_video_image}
      cardId={i.toString()}
    />
  ));

  const {
    title,
    description,
    courses,
    series,
    standalone_videos: standaloneVideos,
  } = curriculumData;

  if (isNotFound) {
    return <Redirect to="/notfound" />;
  }

  if (error) {
    return (
      <div id="discovery-form" role="search" aria-label="course" className="wrapper-search-context">
        <div id="curriculum-data-loaded" style={{ display: 'none' }} />
        <div id="discovery-message" className="search-status-label">{error}</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${title || 'Curriculum'} | ${getConfig().SITE_NAME}`}</title>
      </Helmet>
      {curriculumLoaded && <div id="curriculum-data-loaded" style={{ display: 'none' }} />}
      <div>
        <h3 className="curriculum-header">{title}</h3>
        <p className="curriculum-text">{description}</p>
      </div>
      <hr />
      {courses && courses.length > 0 && (
        <LandingLine
          cards={buildCourseList(courses, curriculaData)}
          title={intl.formatMessage({ id: 'courses', defaultMessage: 'Courses' })}
        />
      )}
      {series && series.length > 0 && (
        <LandingLine
          cards={buildSeriesList(series)}
          title={intl.formatMessage({ id: 'series', defaultMessage: 'Series' })}
        />
      )}
      {standaloneVideos && standaloneVideos.length > 0 && (
        <LandingLine
          cards={buildVideosList(standaloneVideos)}
          title={intl.formatMessage({ id: 'standaloneVideos', defaultMessage: 'Standalone videos' })}
        />
      )}
    </>
  );
};

CurriculumAbout.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CurriculumAbout);
