import React from 'react';
import PropTypes from 'prop-types';
import { CourseCard, VideoCard } from '../components';

/**
 * Render a list of search results.
 */
const SearchResult = ({ infoMsg, paginationMsg, results, isCourse=false }) => {
  /**
  * It’s important to keep the data preparation within the component..
  * Related issue: https://youtrack.raccoongang.com/issue/AI-6175
  */
  const getCourseData = (course) => {
    const data = 'data' in course ? course.data : course;
    return ({
      courseName: data.content ? data.content.display_name : data.display_name,
      courseOrg: data.org,
      courseNumber: data.number,
      courseLink: `/courses/${data.course}/about`,
      courseStart: new Date(data.start),
      courseEnrollmentStart: data.enrollment_start,
      courseImg: data.image_url,
      effort: data.effort ?? null,
      courseShortDesc: data.content ? data.content.short_description : null,
    })
  };

  const getVideoData = (video) => {
    const data = 'data' in video ? video.data : video;
    return ({
      videoUrl: data.video_url,
      videoTitle: data.content.display_name,
      imgUrl: data.course_image_url,
      videoDuration: data.video_duration,
      videoImgUrl: data.content.video_image_url,
    })
  };

  return  (
    <>
      <div id="discovery-form" role="search" aria-label="course" className="wrapper-search-context">
        <div id="discovery-message" className="search-status-label">
          {infoMsg}
          {paginationMsg && (
            <span className="search-status-label-info">
              {paginationMsg}
            </span>
          )}
        </div>
      </div>
      <div className="courses no-course-discovery" role="region" aria-label="List of Results">
        <div className="courses-list-holder">
          <ul className="courses-listing courses-list">
          {isCourse ? (
            <>{results.map((item) => <CourseCard key={item.id || item.course} {...getCourseData(item)} />)}</>
          ) : (
            <>{results.map((item, num) => <VideoCard key={`video-${num}`} cardId={`${num}`} {...getVideoData(item)} />)}</>
          )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SearchResult;

SearchResult.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})),
  infoMsg: PropTypes.string.isRequired,
  paginationMsg: PropTypes.string.isRequired,
};

SearchResult.defaultProps = {
  results: [],
};
