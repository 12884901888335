import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { useHistory } from 'react-router-dom';
import { getVideos } from '../api';

const VideoContext = createContext({});

const VideoContextProvider = injectIntl(({ children, intl }) => {
  const history = useHistory();
  const [videosData, setVideosData] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [loading, setLoading] = useState(false);
  const [infoMsg, setInfoMsg] = useState('');
  const [paginationMsg, setPaginationMsg] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [key, setKey] = useState('courses');
  const videoPageSize = 16;

  const fetchVideos = (query, pageIndex = 0) => getVideos(query, pageIndex, videoPageSize)
    .then(({ total, results }) => {
      setLoading(true);
      setVideosData(results);
      setCurrentPage(pageIndex);
      setPageCount(Math.ceil(total / videoPageSize));
      let message;
      if (results.length > 0) {
        message = intl.formatMessage({ id: 'search.videoResults', defaultMessage: '{count} {count, plural, one {Video} other {Videos}}' }, { count: total });
      } else {
        message = intl.formatMessage({ id: 'search.noVideoResults', defaultMessage: 'We couldn\'t find any results for "{search_string}". Please change your request or try to make your request shorter.' }, { search_string: query });
      }
      if (total > videoPageSize) {
        setPaginationMsg(intl.formatMessage({ id: 'search.pagination', defaultMessage: 'Please scroll down to see more' }));
      } else {
        setPaginationMsg('');
      }
      setInfoMsg(message);
    }).catch(() => {
      setVideosData([]);
      setInfoMsg(intl.formatMessage({ id: 'error.search', defaultMessage: 'There was an error, try searching again.' }));
      setLoading(true);
    });

  const canLoadMore = () => currentPage < pageCount - 1;

  const handleLoadMore = (query) => getVideos(query, currentPage + 1, videoPageSize)
    .then(({ results }) => {
      setVideosData((prevState) => [...prevState, ...results]);
      setCurrentPage((prevPage) => prevPage + 1);
    })
    .catch(() => {
      setVideosData([]);
      setInfoMsg(intl.formatMessage({ id: 'error.search', defaultMessage: 'There was an error, try searching again.' }));
    });

  const handleTab = (value) => {
    setKey(value);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tabParam = queryParams.get('tab');
    if (tabParam) {
      handleTab(tabParam);
      queryParams.delete('tab');
      history.replace({ search: queryParams.toString() });
    }
    fetchVideos(searchString);
  }, []);

  const handleVideoSearch = (value) => {
    setSearchString(value);
    fetchVideos(value);
  };
  return (
    <VideoContext.Provider
      value={{
        videosData,
        searchString,
        loading,
        infoMsg,
        handleVideoSearch,
        canLoadMore,
        handleLoadMore,
        paginationMsg,
        key,
        handleTab,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
});

VideoContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  intl: intlShape.isRequired,
};

export { VideoContextProvider };
export default VideoContext;
