import { getConfig } from '@edx/frontend-platform';
import { getAuthenticatedHttpClient } from '@edx/frontend-platform/auth';

export async function getSeries() {
  const client = getAuthenticatedHttpClient();
  const requestUrl = `${getConfig().LMS_BASE_URL}/get_series/`;
  const { data } = await client.get(requestUrl);
  return data;
}

export async function getCurricula() {
  const client = getAuthenticatedHttpClient();
  const requestUrl = `${getConfig().LMS_BASE_URL}/get_curricula/`;
  const { data } = await client.get(requestUrl);
  return data;
}

export async function getProducts() {
  const client = getAuthenticatedHttpClient();
  const requestUrl = `${getConfig().LMS_BASE_URL}/get_products/`;
  const { data } = await client.get(requestUrl);
  return data;
}

export async function getCurriculum(curriculumId) {
  const client = getAuthenticatedHttpClient();
  const requestUrl = `${getConfig().LMS_BASE_URL}/get_curriculum/${curriculumId}/`;
  const { data } = await client.get(requestUrl);
  return data;
}

export async function getCourseData(courseId) {
  const client = getAuthenticatedHttpClient();
  const requestUrl = `${getConfig().LMS_BASE_URL}/get_course_data/${courseId}/`;
  const { data } = await client.get(requestUrl);
  return data;
}

export async function getSeriesDetails(seriesId) {
  const client = getAuthenticatedHttpClient();
  const requestUrl = `${getConfig().LMS_BASE_URL}/get_series_details/${seriesId}/`;
  const { data } = await client.get(requestUrl);
  return data;
}

export async function getCourses(searchString = '', facets = {}, pageIndex = 0, pageSize = 16, newAndInterestingOnly = false) {
  const form = {
    search_string: searchString,
    page_size: pageSize,
    page_index: pageIndex,
    new_and_interesting_only: newAndInterestingOnly,
    ...facets,
  };
  const formData = new FormData();
  Object.keys(form).forEach((key) => {
    formData.append(key, form[key]);
  });
  const requestData = new URLSearchParams(formData);
  const requestUrl = `${getConfig().LMS_BASE_URL}/search/course_discovery_sorted/`;
  const requestOptions = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };
  const client = getAuthenticatedHttpClient();
  const { data } = await client.post(requestUrl, requestData, requestOptions);
  return data;
}

export async function getVideos(searchString = '', pageIndex = 0, pageSize = 16) {
  const form = {
    search_string: searchString,
    page_size: pageSize,
    page_index: pageIndex,
  };
  const formData = new FormData();
  Object.keys(form).forEach((key) => {
    formData.append(key, form[key]);
  });
  const requestData = new URLSearchParams(formData);
  const requestUrl = `${getConfig().LMS_BASE_URL}/search/video_library_search/`;
  const requestOptions = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };
  const client = getAuthenticatedHttpClient();
  const { data } = await client.post(requestUrl, requestData, requestOptions);
  return data;
}

export async function enrollCourse(courseId) {
  const client = getAuthenticatedHttpClient();
  const requestUrl = `${getConfig().LMS_BASE_URL}/change_enrollment`;
  const form = {
    course_id: courseId,
    enrollment_action: 'enroll',
  };
  const formData = new FormData();
  Object.keys(form).forEach((key) => {
    formData.append(key, form[key]);
  });
  const requestData = new URLSearchParams(formData);
  const { data } = await client.post(requestUrl, requestData);
  return data;
}
