import React, { useContext, useEffect, useState } from 'react';

import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { Button, SearchField, Spinner } from '@edx/paragon';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import VideoContext from '../../context/VideoContext';
import SearchResult from '../../search/SearchResult';
import { scrollToTop } from '../../utils';

const VideoTab = ({
  intl,
  activeKey,
}) => {
  const {
    videosData,
    searchString,
    loading,
    infoMsg,
    handleVideoSearch,
    handleLoadMore,
    canLoadMore,
    paginationMsg,
  } = useContext(VideoContext);

  const [showBackToTop, setShowBackToTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {!loading ? (
        <div className="tab-spinner-holder">
          <Spinner animation="border" variant="info" className="mr-3" screenReaderText="loading" />
        </div>
      ) : (
        <>
          <SearchField
            onSubmit={handleVideoSearch}
            value={searchString}
            placeholder={`${intl.formatMessage({ id: 'search.videos', defaultMessage: 'Search for videos' })}`}
          />
          <InfiniteScroll
            next={() => handleLoadMore(searchString)}
            hasMore={canLoadMore() && activeKey === 'videos'}
            loader={(
              <div className="tab-spinner-holder is-infinite-scroll">
                <Spinner animation="border" variant="info" className="mr-3" screenReaderText="loading" />
              </div>
            )}
            dataLength={videosData.length}
          >
            <SearchResult
              infoMsg={infoMsg}
              paginationMsg={paginationMsg}
              results={videosData}
            />
            <Button className={`back-to-top-btn ${showBackToTop ? 'is-shown' : ''}`} variant="primary" onClick={scrollToTop}>
              <span className="sr-only">
                Back to Top
              </span>
            </Button>
          </InfiniteScroll>
        </>
      )}
    </div>
  );
};

VideoTab.propTypes = {
  intl: intlShape.isRequired,
  activeKey: PropTypes.string.isRequired,
};

export default injectIntl(VideoTab);
