import React from 'react';
import PropTypes from 'prop-types';

const SelectedFacetsList = ({ selectedFacets, handleClearFacet }) => (
  <ul className="active-filters facet-list">
    {Object.entries(selectedFacets).map(([facet, value]) => (
      <li className="active-filter" key={facet}>
        <button
          type="button"
          data-value={value}
          className="facet-option discovery-button"
          onClick={(e) => handleClearFacet(e, facet)}
        >
          <span className="query">{value} </span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
            <path d="m242.72 256 100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
          </svg>
        </button>
      </li>
    ))}
  </ul>
);

SelectedFacetsList.propTypes = {
  selectedFacets: PropTypes.shape({}).isRequired,
  handleClearFacet: PropTypes.func.isRequired,
};

export default SelectedFacetsList;
