import React from 'react';
import { CourseCard, VideoCard } from './components';

export const buildCourseList = (courses) => courses.map((course) => {
  const data = 'data' in course ? course.data : course;
  const courseData = {
    courseName: data.content ? data.content.display_name : data.display_name,
    courseOrg: data.org,
    courseNumber: data.number,
    courseLink: `/courses/${data.course}/about`,
    courseStart: new Date(data.start),
    courseEnrollmentStart: data.enrollment_start,
    courseImg: data.image_url,
    effort: data.effort ?? null,
    courseShortDesc: data.content ? data.content.short_description : null,
  };

  return <CourseCard key={data.id || data.course} {...courseData} />;
});

export const buildVideosList = (videos) => videos.map((video, i) => {
  const data = 'data' in video ? video.data : video;
  const resultData = {
    key: i.toString(),
    videoUrl: data.video_url,
    videoTitle: data.content.display_name,
    imgUrl: data.course_image_url,
    cardId: i.toString(),
    videoDuration: data.video_duration,
    videoImgUrl: data.content.video_image_url,
  };
  return <VideoCard {...resultData} />;
});

/**
 * Filter facets to show only language-specific ones.
 * @param {Object} originalFacets - original facets to filter.
 * @param {Object} curriculaData - filtering criteria for roles and topics.
 * @param {Array} seriesData - filtering criteria for series.
 * @param {Array} productsData - filtering criteria for products.
 * @returns
 */
export const filterFacets = (originalFacets, curriculaData, seriesData, productsData) => {
  if (!Object.keys(originalFacets).length) {
    return originalFacets;
  }
  // Can't use { ...originalFacets } or Object.assign()/Object.create() because nested
  // objects will have the same references (I don't need to change the originalFacets)
  const facets = JSON.parse(JSON.stringify(originalFacets));

  const allowedRoles = [];
  const allowedTopics = [];
  const allowedSeries = [];
  const allowedProducts = [];

  if (curriculaData.role && curriculaData.role.length) {
    curriculaData.role.forEach(item => allowedRoles.push(item.title));
  }

  if (curriculaData.topic && curriculaData.topic.length) {
    curriculaData.topic.forEach(item => allowedTopics.push(item.title));
  }

  seriesData.forEach(item => allowedSeries.push(item.title));
  productsData.forEach(item => allowedProducts.push(item.title));

  facets.role.terms = Object.keys(facets.role.terms)
    .filter(title => allowedRoles.includes(title))
    .reduce((obj, key) => ({
      ...obj,
      [key]: facets.role.terms[key],
    }), {});

  facets.topic.terms = Object.keys(facets.topic.terms)
    .filter(title => allowedTopics.includes(title))
    .reduce((obj, key) => ({
      ...obj,
      [key]: facets.topic.terms[key],
    }), {});

  facets.series.terms = Object.keys(facets.series.terms)
    .filter(title => allowedSeries.includes(title))
    .reduce((obj, key) => ({
      ...obj,
      [key]: facets.series.terms[key],
    }), {});

  facets.product.terms = Object.keys(facets.product.terms)
    .filter(title => allowedProducts.includes(title))
    .reduce((obj, key) => ({
      ...obj,
      [key]: facets.product.terms[key],
    }), {});

  return facets;
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
