import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { getConfig } from '@edx/frontend-platform';
import { enrollCourse } from '../../api';
import { Spinner } from '@edx/paragon';

const CourseDetails = ({
  staffAccess,
  studioUrl,
  courseName,
  overview,
  enrollOptions,
  courseId,
  intl,
}) => {
  const [enrollErrorMessage, setEnrollErrorMessage] = useState('');
  const [optionAction, setOptionAction] = useState({});
  const [selectedCourseId, setSelectedCourseId] = useState(courseId);
  const [enrollmentInProgress, setEnrollmentInProgress] = useState(false);

  useEffect(() => {
    if (enrollOptions.length > 0 && !enrollOptions.find(element => element.option_id == selectedCourseId)) {
      setSelectedCourseId(enrollOptions[0].option_id);
    }
    setOptionAction(getOptionAction(selectedCourseId));
  }, [selectedCourseId]);

  const getOptionAction = (selectedCourseId) => {
    const option = enrollOptions?.find(element => element.option_id == selectedCourseId);
    if (option?.registered) {
      return {
        message: intl.formatMessage({ id: 'courseAbout.enrolled', defaultMessage: 'You are enrolled in this course' }),
        courseTarget: option.course_target,
        disabled: true,
      };
    } if (option?.is_course_full) {
      return {
        message: intl.formatMessage({ id: 'courseAbout.courseIsFull', defaultMessage: 'Course is full' }),
        disabled: true,
      };
    } if (option?.invitation_only && !option?.can_enroll) {
      return {
        message: intl.formatMessage({ id: 'courseAbout.invitationOnlyCourse', defaultMessage: 'Enrollment in this course is by invitation only' }),
        disabled: true,
      };
    } if (!option || option?.is_shib_course && !option?.can_enroll) {
      return {
        message: intl.formatMessage({ id: 'courseAbout.enrollmentIsClosed', defaultMessage: 'Enrollment is Closed' }),
        disabled: true,
      };
    }
    return {
      message: intl.formatMessage({ id: 'courseAbout.enrollNow', defaultMessage: 'Enroll Now' }),
      disabled: false,
    };
  };

  const enrollCourseHandler = async () => {
    setEnrollErrorMessage('');
    setEnrollmentInProgress(true);
    const enrollmentResponse = await enrollCourse(selectedCourseId);
    if (enrollmentResponse === '') {
      window.location.assign(`${getConfig().LMS_BASE_URL}/dashboard`);
    } else {
      setEnrollErrorMessage(enrollmentResponse);
      setEnrollmentInProgress(false);
    }
  };

  const selectLangHandler = (e) => {
    setSelectedCourseId(e.target.value);
  };
  return (
    <>
      {optionAction.disabled && (
        <button
          type="button"
          disabled={optionAction.disabled || enrollmentInProgress}
          className="register"
          onClick={enrollCourseHandler}
        >
          {optionAction.message}
        </button>
      )}
      <div className="details">
        <header className="course-profile">
          <div className="intro-inner-wrapper">
            <div className="table">
              <section className="intro">
                <div className="heading-group">
                  <h1>{courseName}</h1>
                </div>
                <div className="main-cta">
                  {enrollOptions.length > 0 && (
                    <div className="preferred-language-section">
                      <span className="preferred-language-text">
                        {intl.formatMessage({ id: 'courseAbout.enrollIn', defaultMessage: 'Choose Language' })}
                      </span>
                      <select
                        role="select"
                        className="lang-option-select"
                        onChange={selectLangHandler}
                        value={selectedCourseId}
                      >
                        {Object.entries(enrollOptions).map(([_, enrollOption], index) => (
                          <option
                            value={enrollOption.option_id}
                            data-option-index={index}
                            key={index}
                          >
                            {enrollOption.option_lang}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="option-actions">
                    <div id="register_error">{enrollErrorMessage}</div>
                    {enrollmentInProgress ? (
                      <div className="spinner-wrapper">
                        <Spinner animation="border" screenReaderText="loading" variant="info" />
                      </div>
                    ) : (
                      <>
                        {optionAction.courseTarget && (
                          <a href={getConfig().LMS_BASE_URL + optionAction.courseTarget}>
                            {intl.formatMessage({ id: 'courseAbout.viewCourse', defaultMessage: 'View Course' })}
                          </a>
                        )}
                        {!optionAction.disabled && (
                          <button
                            type="button"
                            disabled={optionAction.disabled || enrollmentInProgress}
                            className="register"
                            onClick={enrollCourseHandler}
                          >
                            {optionAction.message}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </header>
        {staffAccess && (
        <div className="wrap-instructor-info studio-view">
          <a className="instructor-info-action" href={studioUrl}>{intl.formatMessage({ id: 'courseAbout.viewInStudio', defaultMessage: 'View About Page in studio' })}</a>
        </div>
        )}
        <div className="inner-wrapper" role="overview" dangerouslySetInnerHTML={{ __html: overview }} />
      </div>
    </>
  );
};

CourseDetails.propTypes = {
  intl: intlShape.isRequired,
  staffAccess: PropTypes.bool.isRequired,
  studioUrl: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
  overview: PropTypes.string.isRequired,
  enrollOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  courseId: PropTypes.string.isRequired,
};

export default injectIntl(CourseDetails);
