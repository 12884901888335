import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import {
  APP_INIT_ERROR, APP_READY, subscribe, initialize,
} from '@edx/frontend-platform';
import { AppProvider, ErrorPage } from '@edx/frontend-platform/react';
import ReactDOM from 'react-dom';

import { messages as headerMessages } from '@edx/frontend-component-header';
import { messages as footerMessages } from '@edx/frontend-component-footer';

import App from './App';
import appMessages from './i18n';
import { VideoContextProvider } from './context/VideoContext';
import { CourseContextProvider } from './context/CourseContext';
import LoginRequiredProvider from './generic/login';

import './scss/index.scss';

subscribe(APP_READY, () => {
  ReactDOM.render(
    <AppProvider>
      <LoginRequiredProvider>
        <CourseContextProvider>
          <VideoContextProvider>
            <App />
          </VideoContextProvider>
        </CourseContextProvider>
      </LoginRequiredProvider>
    </AppProvider>,
    document.getElementById('root'),
  );
});

subscribe(APP_INIT_ERROR, (error) => {
  ReactDOM.render(<ErrorPage message={error.message} />, document.getElementById('root'));
});

initialize({
  messages: [
    appMessages,
    headerMessages,
    footerMessages,
  ],
});
