import React, { useEffect, useState } from 'react';
import { getConfig } from '@edx/frontend-platform';
import PropTypes from 'prop-types';
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

const LoginRequiredProvider = ({ children }) => {
  const [isRedirected, setIsRedirected] = useState(false);
  useEffect(() => {
    if (!getAuthenticatedUser()) {
      setIsRedirected(true);
      window.location.replace(`${getConfig().LMS_BASE_URL}/login?next=${encodeURIComponent(window.location.href)}`);
    }
  }, []);

  return (
    <div>
      {isRedirected === true ? null : children}
    </div>
  );
};

LoginRequiredProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginRequiredProvider;
