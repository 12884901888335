import React, { useContext } from 'react';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { getConfig } from '@edx/frontend-platform';
import { Helmet } from 'react-helmet';
import { Tab, Tabs } from '@edx/paragon';
import VideoTab from './VideoTab';
import CourseTab from './CourseTab';
import VideoContext from '../../context/VideoContext';

const CoursesAndVideos = ({ intl }) => {
  const { key, handleTab } = useContext(VideoContext);
  return (
    <>
      <Helmet>
        <title>{`${intl.formatMessage({ id: 'coursesAndVideos', defaultMessage: 'Courses & Videos' })} | ${getConfig().SITE_NAME}`}</title>
      </Helmet>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => handleTab(k)}
      >
        <Tab eventKey="courses" title={`${intl.formatMessage({ id: 'courses', defaultMessage: 'Courses' })}`}>
          <CourseTab activeKey={key} />
        </Tab>
        <Tab eventKey="videos" title={`${intl.formatMessage({ id: 'videos', defaultMessage: 'Videos' })}`}>
          <VideoTab activeKey={key} />
        </Tab>
      </Tabs>
    </>
  );
};

CoursesAndVideos.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CoursesAndVideos);
