import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@edx/frontend-platform/i18n';

/**
 * Render a line with courses or programs for the landing page.
 */
const LandingLine = ({
  title,
  cards,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleShowAll = (e) => {
    e.preventDefault();
    setIsExpanded((previousState) => !previousState);
  };

  return (
    <div className="courses no-course-discovery" role="region" aria-label="List of Courses">
      <div className="landing-title-wrapper">
        <h3>{`${title} (${cards.length})`}</h3>
        {cards.length > 4 && (
          <a className="show-all" href="/" onClick={handleShowAll}>
            {isExpanded
              ? <FormattedMessage id="showFewer" defaultMessage="Show fewer" />
              : <FormattedMessage id="showAll" defaultMessage="Show all" />}
          </a>
        )}
      </div>
      <ul className="courses-listing courses-list">
        {isExpanded ? cards : cards.slice(0, 4)}
      </ul>
    </div>
  );
};

LandingLine.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.node),
  title: PropTypes.string.isRequired,
};
LandingLine.defaultProps = {
  cards: [],
};

export default LandingLine;
