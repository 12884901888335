import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { useHistory, useLocation } from 'react-router-dom';
import { getCurricula, getSeries, getCourses } from '../api';

const SearchContext = createContext({});

const SearchContextProvider = injectIntl(({ children, intl }) => {
  const history = useHistory();
  const location = useLocation();
  const [seriesData, setSeriesData] = useState([]);
  const [curriculaData, setCurriculaData] = useState({});
  const [newAndInterestingCourses, setNewAndInteresting] = useState([]);
  const [queryString, setQueryString] = useState('');
  const [selectedFacets, setSelectedFacets] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [infoMsg, setInfoMsg] = useState('');
  const [facets, setFacets] = useState({});

  useEffect(() => {
    async function fetchData() {
      await Promise.all([
        getCourses(queryString, selectedFacets, true)
          .then(({ results, aggs }) => {
            setNewAndInteresting(sortNewAndInteresting(results));
            setFacets(aggs);
          }),
        getCurricula().then((curricula) => setCurriculaData(curricula)),
        getSeries().then((series) => setSeriesData(series)),
      ]);
    }
    fetchData();
  }, []);

  const sortNewAndInteresting = (courses) => courses.filter(res => res.data.new_and_interesting_tag
      && new Date(res.data.new_and_interesting_expiration).getTime() >= Date.now())
    .sort(
      // eslint-disable-next-line comma-dangle
      (a, b) => new Date(b.data.new_and_interesting_expiration) - new Date(a.data.new_and_interesting_expiration)
    );

  const fetchCourses = (query, facetsData) => getCourses(query, facetsData)
    .then(({ results, aggs }) => {
      setSearchResult(results);
      setFacets(aggs);
      let message;
      if (results.length > 0) {
        message = intl.formatMessage(
          { id: 'search.results', defaultMessage: 'Results ({count})' },
          { count: results.length },
        );
      } else {
        message = intl.formatMessage(
          { id: 'search.noResults', defaultMessage: 'We couldn\'t find any results for {searchString}' },
          { searchString: queryString },
        );
      }
      setInfoMsg(message);
      if (location.pathname !== '/courses') {
        history.push('/courses');
      }
    })
    .catch(() => {
      setSearchResult([]);
      setInfoMsg(intl.formatMessage({ id: 'error.search', defaultMessage: 'There was an error, try searching again.' }));
    });

  /**
  * Remove one facet from selected facets and search for courses.
  * @param {Event} e - button click event.
  * @param {string} key - facet name.
  */
  const handleClearFacet = (e, key) => {
    e.preventDefault();
    const facetsCopy = { ...selectedFacets };
    if (key in facetsCopy) {
      delete facetsCopy[key];
    }
    setSelectedFacets(facetsCopy);
    fetchCourses(queryString, facetsCopy);
    if (location.pathname !== '/courses') {
      history.push('/courses');
    }
  };

  /**
  * Remove all selected facets and search for courses.
  * @param {Event} e - button click event.
  */
  const handleClearAllFacets = (e) => {
    e.preventDefault();
    setSelectedFacets({});
    fetchCourses(queryString, {});
    if (location.pathname !== '/courses') {
      history.push('/courses');
    }
  };

  /**
  * Set search string on input change.
  * @param {Event} e - onChange event from text input element.
  */
  const handleQueryStringChange = (e) => {
    setQueryString(e.target.value);
  };

  /**
  * Process facet select.
  * @param {Event} e - onChange event from select element.
  * @param {string} facet - Facet name.
  */
  const handleFacetChange = (e, facet) => {
    const selectedFacetsCopy = { ...selectedFacets };
    if (e.target.value) {
      selectedFacetsCopy[facet] = e.target.value;
    } else {
      delete selectedFacetsCopy[facet];
    }
    setSelectedFacets(selectedFacetsCopy);
    fetchCourses(queryString, selectedFacetsCopy);
    if (location.pathname !== '/courses') {
      history.push('/courses');
    }
  };

  return (
    <SearchContext.Provider
      value={{
        queryString,
        setQueryString,
        searchResult,
        newAndInterestingCourses,
        seriesData,
        curriculaData,
        handleQueryStringChange,
        handleClearAllFacets,
        handleFacetChange,
        handleClearFacet,
        infoMsg,
        facets,
        selectedFacets,
        handleSearch: () => fetchCourses(queryString, selectedFacets),
      }}
    >
      {children}
    </SearchContext.Provider>
  );
});

SearchContextProvider.propTypes = {
  /** Specifies content of the component. */
  children: PropTypes.node.isRequired,
  intl: intlShape.isRequired,
};

export { SearchContextProvider };
export default SearchContext;
