
import React, { useContext, useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { getConfig } from '@edx/frontend-platform';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';

import SearchContext from '../../context/SearchContext';
import { buildCourseList } from '../../utils';
import { getSeriesDetails } from '../../api';

/**
 * Series component that renders body of series about page.
 * NOTE: the component contains conditional 'go back' button which should be rendered
 * only if this component was accessed through curriculum about page. The React way of handling
 * this button would be to use history API and react-router, but it breaks videos on
 * curriculum about page (more info in the comments around the code for the button),
 * so document.referrer and <a> tag should be used instead.
 */
const SeriesAbout = ({ intl }) => {
  const { curriculaData } = useContext(SearchContext);
  const { seriesId } = useParams();
  const [seriesData, setSeriesData] = useState({});
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => {
    getSeriesDetails(seriesId)
      .then(responseData => setSeriesData(responseData))
      .catch((err) => {
        if (err.customAttributes.httpErrorStatus === 404) {
          setIsNotFound(true);
        }
      });
  }, []);

  const { title, description, courses } = seriesData;
  const { referrer } = document;
  const showGoBack = /curriculum\/((\w+)-?)+\/?$/.test(referrer);

  if (isNotFound) {
    return <Redirect to="/notfound" />;
  }

  return (
    <>
      <Helmet>
        <title>{`${title || 'Series'} | ${getConfig().SITE_NAME}`}</title>
      </Helmet>
      <div>
        <h3 className="curriculum-header">{title}</h3>
        <p className="curriculum-text">{description}</p>
      </div>
      <hr />
      {showGoBack && (
        // Ideally, we would want to use history.goBack() here but we can't because that way
        // page would not get reloaded which causes issues with standalone videos
        // on the curriculum page (script that handles videos is not run)
        <a className="curriculum-back-link" href={referrer}>
          {intl.formatMessage({ id: 'goBack', defaultMessage: 'Go back to curriculum' })}
        </a>
      )}
      {courses && courses.length > 0 && (
        <div className="courses no-course-discovery" role="region" aria-label="List of Courses">
          <div className="landing-title-wrapper">
            <h3>{intl.formatMessage({ id: 'courses', defaultMessage: 'Courses' })} ({courses.length})</h3>
          </div>
          <ul className="courses-listing courses-list">
            {buildCourseList(courses, curriculaData)}
          </ul>
        </div>
      )}
    </>
  );
};

SeriesAbout.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SeriesAbout);
