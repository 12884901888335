import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getConfig } from '@edx/frontend-platform';
import { FormattedMessage } from '@edx/frontend-platform/i18n';

/**
 * Curriculum Card component that renders provided curriculum data.
 * Mimics the CourseCard component.
 */
const CurriculumCard = ({ curriculumName, curriculumImg, curriculumLink }) => (
  <li className="courses-listing-item">
    <article className="course" id="course.id" role="region" aria-label={curriculumName}>
      <Link to={curriculumLink} style={{ textDecoration: 'none' }}>
        <header className="course-image">
          <div className="cover-image">
            <img src={curriculumImg} alt={curriculumName} />
            <div className="learn-more" aria-hidden="true">
              <FormattedMessage id="learnMore" defaultMessage="LEARN MORE" />
            </div>
          </div>
        </header>
        <div className="course-info" aria-hidden="true">
          <h2 className="course-name">
            <span className="course-title">{curriculumName}</span>
          </h2>
        </div>
      </Link>
    </article>
  </li>
);

CurriculumCard.propTypes = {
  curriculumName: PropTypes.string.isRequired,
  curriculumImg: PropTypes.string,
  curriculumLink: PropTypes.string.isRequired,
};

CurriculumCard.defaultProps = {
  curriculumImg: '',
};

export default CurriculumCard;
