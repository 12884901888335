/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@edx/frontend-platform/i18n';
import { getConfig } from '@edx/frontend-platform';
import ReactModal from 'react-modal';

/**
 * Standalone video Card component.
 * When clicked a modal window is opened with standalone video.
 */
const StandaloneVideoCard = ({
  videoUrl,
  videoTitle,
  imgUrl,
  cardId,
  videoDuration,
  videoImgUrl,
}) => {
  const [displayModal, setDisplayModal] = useState(false);

  const handleCloseModal = e => {
    e.preventDefault();
    e.stopPropagation();
    setDisplayModal(false);
  };

  const handleOpenModal = e => {
    e.preventDefault();
    setDisplayModal(true);
  };

  return (
    <>
      <li className="courses-listing-item courses-listing-item--standalone-videos" onClick={handleOpenModal}>
        <article className="course no-course-discovery" id={cardId} role="region" aria-label={videoTitle}>
          <header className="course-image">
            <div className="cover-image">
              <img src={videoImgUrl || `${getConfig().LMS_BASE_URL}${imgUrl}`} alt="" />
              <div className="learn-more" aria-hidden="true">
                <FormattedMessage id="watchVideo" defaultMessage="Watch the video" />
              </div>
            </div>
          </header>
          <div className="course-info" aria-hidden="true">
            <h2 className="course-name course-video-name">
              <span className="course-video-tag">
                Video
              </span>
              <span className="course-title course-video-title">{videoTitle}</span>
              {videoDuration && <span className="course-video-duration">Duration: {videoDuration}</span>}
            </h2>
          </div>
        </article>
      </li>
      <ReactModal
        isOpen={displayModal}
        style={{ overlay: { zIndex: 999 }, content: { display: 'block' } }}
        className="modal video-modal video-library"
        ariaHideApp={false}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={false}
        preventScroll
      >
        <span className="close-video-modal" onClick={handleCloseModal} aria-hidden="true" />
        <div className="inner-wrapper">
          <div className="fluid-iframe">
            <iframe
              title="standalone-video"
              id="viewer-frame"
              scrolling="yes"
              src={videoUrl}
              allowFullScreen
              frameBorder="0"
            />
          </div>
        </div>
      </ReactModal>
    </>
  );
};

StandaloneVideoCard.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  videoTitle: PropTypes.string,
  imgUrl: PropTypes.string.isRequired,
  cardId: PropTypes.string.isRequired,
  videoDuration: PropTypes.string,
  videoImgUrl: PropTypes.string.isRequired,
};

StandaloneVideoCard.defaultProps = {
  videoTitle: '',
  videoDuration: null,
};

export default StandaloneVideoCard;
