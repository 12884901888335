const FACETS = Object.freeze({
  PRODUCT: 'product',
  ROLE: 'role',
  TOPIC: 'topic',
  SERIES: 'series',
  LANGUAGE: 'language',
});

// eslint-disable-next-line import/prefer-default-export
export { FACETS };
