import React, { useContext, useEffect, useState } from 'react';
import { Button, Spinner } from '@edx/paragon';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import CourseContext from '../../context/CourseContext';
import SearchBar from '../../search/SearchBar';
import { filterFacets, scrollToTop } from '../../utils';
import FilterBar from '../../search/FilterBar';
import SearchResult from '../../search/SearchResult';

const CourseTab = ({ activeKey }) => {
  const {
    curriculaData,
    seriesData,
    productsData,
    facets,
    queryString,
    selectedFacets,
    handleFacetChange,
    handleSearch,
    handleClearFacet,
    handleClearAllFacets,
    loading,
    searchResult,
    infoMsg,
    handleLoadMore,
    canLoadMore,
    paginationMsg,
  } = useContext(CourseContext);

  const [showBackToTop, setShowBackToTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {!loading ? (
        <div className="tab-spinner-holder">
          <Spinner animation="border" variant="info" className="mr-3" screenReaderText="loading" />
        </div>
      ) : (
        <>
          <SearchBar
            facets={filterFacets(facets, curriculaData, seriesData, productsData)}
            queryString={queryString}
            selectedFacets={selectedFacets}
            handleFacetChange={handleFacetChange}
            handleSearch={handleSearch}
          />
          {Object.keys(selectedFacets).length > 0 && (
            <FilterBar
              selectedFacets={selectedFacets}
              handleClearFacet={handleClearFacet}
              handleClearAllFacets={handleClearAllFacets}
            />
          )}
          <InfiniteScroll
            next={() => handleLoadMore(queryString, selectedFacets)}
            hasMore={canLoadMore() && activeKey === 'courses'}
            loader={(
              <div className="tab-spinner-holder is-infinite-scroll">
                <Spinner animation="border" variant="info" className="mr-3" screenReaderText="loading" />
              </div>
            )}
            dataLength={searchResult.length}
          >
            <SearchResult
              infoMsg={infoMsg}
              paginationMsg={paginationMsg}
              results={searchResult}
              isCourse={true}
            />
            <Button className={`back-to-top-btn ${showBackToTop ? 'is-shown' : ''}`} variant="primary" onClick={scrollToTop}>
              <span className="sr-only">
                Back to Top
              </span>
            </Button>
          </InfiniteScroll>
        </>
      )}
    </>
  );
};

CourseTab.propTypes = {
  activeKey: PropTypes.string.isRequired,
};

export default CourseTab;
