import React, { useContext } from 'react';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import CurriculumCard from './CurriculumCard';
import SearchResult from '../../search/SearchResult';
import { LandingLine } from '../../components';
import { buildCourseList } from '../../utils';
import SearchContext from '../../context/SearchContext';
import {getConfig} from "@edx/frontend-platform";
import {Helmet} from "react-helmet";

const DiscoverCourses = ({ intl }) => {
  const {
    searchResult,
    newAndInterestingCourses,
    curriculaData,
    infoMsg,
  } = useContext(SearchContext);

  const getCurriculaCards = (curriculaTypeData) => curriculaTypeData.map((curriculum) => (
    <CurriculumCard
      key={curriculum.curriculum_id}
      curriculumImg={`${curriculum.image}`}
      curriculumName={curriculum.title}
      curriculumLink={`/curriculum/${curriculum.curriculum_id}`}
    />
  ));

  if (searchResult.length > 0 || infoMsg) {
    return (
      <SearchResult
        message={infoMsg}
        courses={buildCourseList(searchResult, curriculaData)}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${intl.formatMessage({ id: 'courses', defaultMessage: 'Courses' })} | ${getConfig().SITE_NAME}`}</title>
      </Helmet>
      <LandingLine
        cards={buildCourseList(newAndInterestingCourses, curriculaData)}
        title={intl.formatMessage({ id: 'newAndInteresting', defaultMessage: 'New & Interesting' })}
      />
      <LandingLine
        cards={curriculaData.role ? getCurriculaCards(curriculaData.role) : []}
        title={intl.formatMessage({ id: 'byRole', defaultMessage: 'By Role' })}
      />
      <LandingLine
        cards={curriculaData.topic ? getCurriculaCards(curriculaData.topic) : []}
        title={intl.formatMessage({ id: 'byTopic', defaultMessage: 'By Topic' })}
      />
    </>
  );
};

DiscoverCourses.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(DiscoverCourses);
